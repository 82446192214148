import React from "react";

//监听页面是否用户可见
export default class PageVisible extends React.PureComponent {
  constructor(props) {
    super(props);

    let hidden, vstate, visibilityChange;
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
      vstate = "visibilityState";
    } else if (typeof document.mozHidden !== "undefined") {
      hidden = "mozHidden";
      visibilityChange = "mozvisibilitychange";
      vstate = "mozVisibilityState";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
      vstate = "msVisibilityState";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
      vstate = "webkitVisibilityState";
    }

    this.hidden = hidden;
    this.vstate = vstate;
    this.visibilityChange = visibilityChange;
  }

  componentDidMount() {
    document.addEventListener(this.visibilityChange, this.callback, false);
  }

  callback = (event) => {
    if (document[this.vstate] === this.hidden) {
      if (typeof this.props.onHide === "function") this.props.onHide(event);
    } else {
      if (typeof this.props.onShow === "function") this.props.onShow(event);
    }
  };

  componentWillUnmount() {
    document.removeEventListener(this.visibilityChange, this.callback, false);
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}
