import React from "react"
import PageVisible from "../PageVisible"
import "./index.less"

let Tc
let scene
class ThreeTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageOnTheFront: true,
      show3D: false,
      renderWidth: 500,
      renderHeight: 500,
    }
  }

  componentDidMount() {
    let _this = this

    import("../ThreeCanvas").then(({ ThreeCanvas }) => {
      Tc = ThreeCanvas
      _this.props.imp.then(({ Scene }) => callback(Scene))
    })

    function callback(Scene) {
      scene = Scene
      _this.setState({
        show3D: true,
        renderWidth: document.body.clientWidth,
        renderHeight: document.body.clientHeight,
      })
    }
    window.addEventListener("resize", this.onWindowResize, false)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize, false)
  }

  onWindowResize = ev => {
    this.setState({
      renderWidth: document.body.clientWidth,
      renderHeight: document.body.clientHeight,
    })
  }

  render() {
    const { renderWidth, renderHeight, pageOnTheFront, show3D } = this.state
    return (
      <div>
        {show3D && (
          <>
            <Tc
              width={renderWidth}
              height={renderHeight}
              devicePixelRatio={window.devicePixelRatio}
              render={(renderer, time) => {
                return pageOnTheFront
              }}
              scene={scene}
            />
            <PageVisible
              onShow={() => {
                console.log("show")
                this.setState({ pageOnTheFront: true })
              }}
              onHide={() => {
                console.log("hide")
                this.setState({ pageOnTheFront: false })
              }}
            />
          </>
        )}
      </div>
    )
  }
}

export default ThreeTemplate
