import React from "react"
import ThreeTemplate from "../../components/ThreeTemplate"

class CityEffect extends React.Component {
  render() {
    return (
      <ThreeTemplate imp={import("../../Scenes/CityScene")}></ThreeTemplate>
    )
  }
}

export default CityEffect
